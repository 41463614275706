@media (min-width: 1200px) {
  .eco-text-container {
    width: 45%;
    line-height: 1.6;
    margin-top: 80px;
  }
  #eco-map-container {
    width: 45%;
  }
}

@media (max-width: 1200px) {
  .eco-text-container {
    width: 45%;
    line-height: 1.5;
    margin-top: 80px;
  }
  #eco-map-container {
    width: 45%;
  }
}

@media (max-width: 992px) {
  .eco-text-container {
    width: 100%;
    line-height: 1.4;
    margin-top: 67px;
  }
  #eco-map-container {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .eco-text-container {
    width: 100%;
    line-height: 1.4;
    margin-top: 60px;
  }
  #eco-map-container {
    width: 100%;
  }
}

.eco-text-container {
  float: left;
  margin-left: 5%;
  padding-right: 4%;
  color: #282c34;
}

#eco-map-container {
  margin-top: 0px;
  margin-right: 1%;
  float: left;
  top: auto;
  bottom: auto;
  padding: 5px;
}
