@media (min-width: 1200px) {
  /* .economy-switch {
    margin-left: auto;
    margin-right: 10px;
  } */

  .nav-link:hover {
    color: #b50900 !important;
    font-weight: bold;
    transform: scale(1.01);
  }
}

@media (max-width: 1200px) {
  /* .economy-switch {
    margin-left: auto;
    margin-right: 10px;
  } */

  .nav-link:hover {
    color: #b50900 !important;
    font-weight: bold;
    transform: scale(1.01);
  }
}

@media (max-width: 992px) {
  /* .economy-switch {
    margin: 10px;
  } */

  #view-nav-link {
    padding-left: 10px !important;
    display: inline-block;
    box-sizing: border-box;
    transition: color 0.1s ease-in-out, transform 0.1s ease-in-out;
    min-width: 105px;
    text-align: center;
  }

  .nav-link:hover {
    color: #b50900 !important;
    font-weight: bold;
    transform: scale(1.01);
  }

  .view-bar-container {
    flex-direction: column;
  }

  .economy-switch-div {
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  /* .economy-switch {
    margin: 10px;
  } */

  #view-nav-link {
    padding-left: 6px !important;
  }
}

#view-nav-link {
  margin: 0px 9px;
  font-size: medium;
  display: inline-block;
  box-sizing: border-box;
  transition: color 0.1s ease-in-out, transform 0.1s ease-in-out;
  min-width: 105px;
  text-align: center;
}

.nav-link.active {
  color: #fff !important;
  background: #b50900 !important;
  box-sizing: border-box;
  transition: color 0.1s ease-in-out, transform 0.1s ease-in-out;
  min-width: 105px;
  text-align: center;
}

.nav-link.active:hover {
  font-weight: normal;
  transform: scale(1.02);
  text-align: center;
}

.economy-switch-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
}

.form-check-label {
  font-size: medium;
}

.left-label {
  padding-right: 10px;
}

.form-check {
  font-size: medium;
}

.form-check-input:checked {
  background-color: #b50900;
  border-color: #b50900;
}

