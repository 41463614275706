html, body {
  --transition-speed: 600ms;
}

@media (min-width: 1200px) {
  body {
    font-size: 16px;
  }
  .Page h1 {
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  body {
    font-size: 14px;
  }
  .Page h1 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  body {
    font-size: 13px;
  }
  .Page h1 {
    font-size: 17px;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 12px;
  }
  .Page h1 {
    font-size: 15px;
  }
}

body {
  background-color: white;
  color: #282c34;
  font-family: Arial;
  overflow-y: scroll;
}

.Page h1 {
  color: #b80900;
  font-weight: bold;
}

.Page {
  display: inline-block;
  padding-top: 1.75vh;
  padding-bottom: 1.75vh;
  margin-left: 3vw;
  margin-right: 3vw;
  width: 90%;
}
