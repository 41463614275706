@import "../base-definitions.scss";

@media (min-width: 1200px) {
    %button-base {
        font-size: $standart-font-size-xl*1.1;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: right;
        width: 100%;
        border: none;
    }

    %view-nav-base {
        right: 0;
        position:fixed;
        top: 36%
    }
}

@media (max-width: 1200px) {
    %button-base {
        font-size: $standart-font-size-lg*1.1;
        padding-top: 13px;
        padding-bottom: 13px;
        text-align: right;
        width: 100%;
        border: none;
    }

    %view-nav-base {
        right: 0;
        position:fixed;
        top: 36%
    }
}

@media (max-width: 992px) {
    %button-base {
        font-size: $standart-font-size-md;
        text-align: left;
        width: 23vw;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 2%;
    }

    %view-nav-base {
        width: 100%;
        position: relative;
    }
}

@media (max-width: 576px) {
    %button-base {
        font-size: $standart-font-size-sm;
        text-align: left;
        width: 23vw;    
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 2%;    }

    %view-nav-base {
        width: 100%;
        position: relative;
    }
}

.group-btn {
    @extend %button-base;
    background-color: $app-background-color;
    color: $text-color;
    font-weight: bolder;
    padding-right: 0;
}

.group-btn:hover {
    color: $hover-color;
}

.view-nav {
    @extend %view-nav-base;
}
  