$standart-font-family: Arial;
$header-color: rgb(184,9,0);
$text-color: #282c34;
$navbar-text-color: #333;
$hover-color: darkblue;
$annotation-color: rgb(130,130,130);

$app-background-color: white;
$navbar-background-color: rgb(224, 0, 0);


$annotation-font-size-sm: 11px;
$annotation-font-size-md: 13px;
$annotation-font-size-lg: 14px;
$annotation-font-size-xl: 15px;

$standart-font-size-sm: 12px;
$standart-font-size-md: 13px;
$standart-font-size-lg: 14px;
$standart-font-size-xl: 16px;

$navbar-font-size-sm: 13px;
$navbar-font-size-md: 15px;
$navbar-font-size-lg: 18px;
$navbar-font-size-xl: 20px;

$heading-font-size-sm: 15px;
$heading-font-size-md: 17px;
$heading-font-size-lg: 20px;
$heading-font-size-xl: 22px;