@import "../base-definitions.scss";

@media (min-width: 1200px) {
    %footer-distributed-base {
      font-size: $navbar-font-size-xl;
    }
    
    %copyright-base {
      font-size: $annotation-font-size-xl;
    }

    #footer-logo {
      height: 110px;
      padding: 4px;
    }
}

@media (max-width: 1200px) {
  %footer-distributed-base {
      font-size: $navbar-font-size-lg;
  }
  
  %copyright-base {
      font-size: $annotation-font-size-lg;
  }

  #footer-logo {
    height: 110px;
    padding: 3px;
  }
}

@media (max-width: 992px) {
  %footer-distributed-base {
      font-size: $navbar-font-size-md;
  }
  
  %copyright-base {
      font-size: $annotation-font-size-md;
  }

  #footer-logo {
    height: 100px;
    padding: 2px;
  }
}

@media (max-width: 576px) {
  %footer-distributed-base {
      font-size: $navbar-font-size-sm;
  }
    
  %copyright-base {
      font-size: $annotation-font-size-sm;
  }

  #footer-logo {
    height: 90px;
    padding: 1px;
    padding-left: 0;
    margin-left: 0px
  }
}

.footer-distributed {
  @extend %footer-distributed-base;
  background-color: $navbar-background-color;
  width: 100%;
  padding-left: 10px;
}

.link-container {
  max-width: 100%;
  display: inline-block;
}

#footer-link {
  color: $navbar-text-color;
  margin-right: 20px;
  padding: 0;
  display: inline;
}

#footer-link:hover {
  color: $hover-color;
}

.copyright {
  @extend %copyright-base;
  color: $annotation-color;
  margin: 0;
  margin-top: 2px;
  margin-left: 0px;

  a {
    text-decoration: underline;
    color: $annotation-color;
  }
}