@import "../../Components/base-definitions.scss";

@media (min-width: 1200px) {
    %heading-base {
        font-size: $heading-font-size-xl*1.5;
    }

    %text-container-base {
        width: 40%;
        line-height: 1.6;
        margin-top: 80px;
    }

    %map-container-base {
        width: 50%;
    }

    %hover-text-base {
        padding: 8px;    
    }
}
  
@media (max-width: 1200px) {
    %heading-base {
        font-size: $heading-font-size-lg*1.5;
    }
    
    %text-container-base {
        width: 40%;
        line-height: 1.5;
        margin-top: 80px;
    }

    %map-container-base {
        width: 50%;
    }

    %hover-text-base {
        padding: 7px;
    }
}
  
@media (max-width: 992px) {
    %heading-base {
        font-size: $heading-font-size-md*1.5;
    }

    %text-container-base {
        width: 100%;
        line-height: 1.4;
        margin-top: 67px;
    }

    %map-container-base {
        width: 100%;
    }

    %hover-text-base {
        width: 60%;
        padding: 6px;    
    }
}
  
@media (max-width: 576px) {
    %navbar-distributed-base {
        font-size: $heading-font-size-sm*1.5;
    }

    %text-container-base {
        width: 100%;
        line-height: 1.4;
        margin-top: 60px;
    }

    %map-container-base {
        width: 100%;
    }

    %hover-text-base {
        width: 60%;
        padding: 5px;
    }
}

.strategy-text-container {
    @extend %text-container-base;
    float: left;
    color: $text-color;
    margin-left: 3%;
    padding-right: 3%;
}
  
#strategy-map-container {
    @extend %map-container-base;
    margin-top: 2%;
    margin-right: 1%;
    float: left;
    top:auto;
    bottom:auto;
    margin-left: 3%;
}
