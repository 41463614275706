@media (min-width: 1200px) {
  .text-container-partners {
    width: 45%;
    margin-left: 3%;
    margin-top: 3%;
  }
  .image-container-partners {
    width: 30%;
    margin-left: 8%;
  }
}

@media (max-width: 1200px) {
  .text-container-partners {
    width: 45%;
    margin-left: 3%;
    margin-top: 3%;
  }
  .image-container-partners {
    width: 30%;
    margin-left: 8%;
  }
}

@media (max-width: 992px) {
  .text-container-partners {
    width: 100%;
  }
  .image-container-partners {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .text-container-partners {
    width: 100%;
  }
  .image-container-partners {
    width: 100%;
    margin-left: 0;
  }
}

.text-container-partners {
  float: left;
  color: #282c34;
}

.image-container-partners {
  margin-top: 0px;
  margin-right: 1%;
  float: left;
  top: auto;
  bottom: auto;
  padding: 5px;
}

.partner-image {
  width: 100%;
}
