.main-page {
  min-height: 80vh;
  width: 100%;
  margin: auto;
  padding: 10px;
}

area {
  outline-color: rgb(25, 224, 35);
}


